import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { SearchBar } from "./SearchBar";
import CreateUrlButton from "./CreateUrlButton";
import { GroupSelector } from "common/layout/Header/GroupSelector";
import { usePermissions } from "common/Permissions";
import Loader from "common/Loader";

export const AttachmentModalBase = ({
  showModal,
  setShowModal,
  type,
  types,
  attachedData,
  attach,
  detach,
  refetch,
  id,
  ...props
}) => {
  const { group } = usePermissions();
  const [groupID, setGroupID] = useState(group.id);
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<any>();
  const getSelectedGroupData = () => {
    type.value === "Url" || type.value === "File"
      ? type
          ?.getItemsInSelectedGroup?.({
            variables: { groupID },
          })
          .then((issues) => {
            setEntities(issues);
          })
      : type
          ?.getItemsInSelectedGroup?.({
            variables: { id: groupID },
          })
          .then((issues) => {
            setEntities(issues.filter((issue) => issue.id !== id));
          });
  };
  useEffect(() => {
    getSelectedGroupData();
  }, [groupID]);

  useEffect(() => {
    setLoading(false);
  }, [entities]);

  useEffect(() => {
    setGroupID(group.id);
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header>
        Attach {type?.name}
        <div className="float-right">
          <GroupSelector
            key={group.id}
            groupID={groupID}
            isAttachment
            onChange={(id) => {
              setLoading(true);
              setGroupID(id);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {loading === true ? (
          <div className="text-center">
            <Spinner size="sm" animation="border" variant="primary" />
          </div>
        ) : (
          <SearchBar
            {...{
              types,
              attachedData,
              entities,
              attach,
              detach,
              refetch,
              id,
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {type.CreateItemButton && (
          <type.CreateItemButton
            style={{ marginLeft: "10px" }}
            defaultValues={props.assementData}
            onComplete={(item) => {
              const id = item?.[0]?.id;
              if (id) {
                attach(id);
              }
              getSelectedGroupData();
            }}
          />
        )}
        {type.name === "URLs" && id && (
          <CreateUrlButton
            style={{ marginLeft: "10px" }}
            variant="primary"
            refetch={refetch}
            refetchGroupData={getSelectedGroupData}
            id={id}
          />
        )}
        <Button
          variant="light"
          className="cancel-btn"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
